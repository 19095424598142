<template>
  <b-row>
    <b-colxx xxs="12" lg="12" xl="12" class="pr-6 display">
      <b-card class="mb-12 mr-12" :title="title">
        <b-form
          @submit.stop.prevent="onValidateProgramFormSubmit"
          class="av-tooltip tooltip-label-top"
        >
          <b-row>
            <b-colxx sm="3">
              <b-form-group label="Program Initials">
                <b-form-input
                  type="text"
                  size="lg"
                  v-model="$v.program.programInitials.$model"
                  :state="validateProgramState('programInitials')"
                />
                <b-form-invalid-feedback
                  v-if="!$v.program.programInitials.required"
                  >Please enter Program Initials!</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-else-if="!$v.program.programInitials.maxLength"
                  >Maximum Characters Allowed 10!</b-form-invalid-feedback
                >
              </b-form-group>
            </b-colxx>
            <b-colxx sm="4">
              <b-form-group label="Program Description">
                <b-form-input
                  type="text"
                  v-model.trim="$v.program.programDescription.$model"
                  :state="validateProgramState('programDescription')"
                />
                <b-form-invalid-feedback
                  v-if="!$v.program.programDescription.maxLength"
                  >Maximum Characters Allowed 150!</b-form-invalid-feedback
                >
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="6">
              <b-form-group label="Program Detail Description">
                <b-form-textarea
                  v-model.trim="program.programDetailDescription"
                  rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="6">
              <b-form-group label="Web Description">
                <b-form-input
                  type="text"
                  v-model.trim="$v.program.programWebDescription.$model"
                  :state="validateProgramState('programWebDescription')"
                />
                <b-form-invalid-feedback
                  v-if="!$v.program.programWebDescription.maxLength"
                  >Maximum Characters Allowed 150!</b-form-invalid-feedback
                >
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="3">
              <b-form-group label="Display Order">
                <b-form-input
                  v-model.trim="$v.program.displayOrder.$model"
                  :state="validateProgramState('displayOrder')"
                />
                <b-form-invalid-feedback
                  v-if="!$v.program.displayOrder.required"
                  >Display Order Required!</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-else-if="!$v.program.displayOrder.numeric"
                  >Value must be a number!</b-form-invalid-feedback
                >
              </b-form-group>
            </b-colxx>
            <b-colxx sm="3">
              <b-form-group label="Program Active">
                <switches
                  v-model="program.active"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="3">
              <b-form-group label="Active Budget">
                <switches
                  v-model="program.activeBudget"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="3">
              <b-form-group label="Active International">
                <switches
                  v-model="program.activeInternational"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row align-h="left">
            <b-colxx xl="2" lg="4">
              <b-button
                block
                variant="danger"
                class="mt-4"
                size="lg"
                @click="cancelProgramForm()"
                >{{ $t('forms.cancel') }}
              </b-button>
            </b-colxx>
            <b-colxx lg="4" xl="2">
              <b-button
                block
                type="submit"
                variant="success"
                class="mt-4"
                size="lg"
                >{{ $t('forms.submit') }}
              </b-button>
            </b-colxx>
          </b-row>
        </b-form>
      </b-card>
    </b-colxx>
  </b-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import Switches from 'vue-switches'
import ProgramMixin from '../../../../mixins/ProgramMixin.vue'
import DirtyDataMixin from '../../../../mixins/DirtyDataMixin.vue'

const {
  required,
  maxLength,
  minLength,
  numeric
} = require('vuelidate/lib/validators')

export default {
  components: {
    switches: Switches
  },

  props: {
    programID: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      title: null
    }
  },
  async created () {
    if (!this.programID) {
      this.title = 'Add New Program'
      this.program = {
        programInitials: '',
        programDescription: '',
        programDetailDescription: '',
        programWebDescription: '',
        displayOrder: null
      }
    } else {
      this.title = 'Edit Program'
      await this.getProgramById(this.programID)
    }
    this.initDirtyDataWatcher('program')
  },
  mixins: [validationMixin, ProgramMixin, DirtyDataMixin],
  validations: {
    program: {
      programInitials: {
        required,
        maxLength: maxLength(10)
      },
      programDescription: {
        maxLength: maxLength(150),
        minLength: minLength(2)
      },
      programWebDescription: {
        maxLength: maxLength(150),
        minLength: minLength(2)
      },
      displayOrder: {
        required,
        numeric
      }
    }
  },
  methods: {
    validateProgramState (name) {
      const { $dirty, $error } = this.$v.program[name]
      return $dirty ? !$error : null
    },
    onValidateProgramFormSubmit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.saveProgram(this.program)
      }
    },
    async saveProgram (program) {
      this.program.programID
        ? await this.updateProgram(program)
        : await this.addNewProgram(program)
      if (this.statusCode === 200) {
        this.setDirtyData(false)
        this.$router.push({ name: 'programsTable' })
      }
    },
    cancelProgramForm () {
      this.$router.push({ name: 'programsTable' })
    }
  },
  computed: {}
}
</script>

<style scoped>
.form-control.is-invalid {
  border-color: #dc3545;
}
</style>
